<!--
 * @Author: your name
 * @Date: 2020-06-05 14:03:47
 * @LastEditTime: 2020-08-17 14:27:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\views\login\login.vue
-->
<template>
  <div class="login">
    <!--banner-->
    <div class="banner">
      <!--背景图-->
      <img src="https://img.irisgem.com.cn//pc/loginpic.jpg" alt />
      <!--登录div-->
    <div v-if="!watch_show">
      <div class="log_main" v-if="isshow">
        <p class="title" v-if="reveal == false">账号密码登录</p>
        <p class="title" v-if="reveal">手机快捷登录</p>
        <!--密码登录-->
        <div v-if="reveal == false" class="passlogin">
          <div class="input">
            <input
              type="text"
              placeholder="请输入手机号"
              @input="()=>{this.tel=this.tel.replace(/[^\d]/g,'')}"
              v-model="tel"
            />
            <input type="password" placeholder="请输入密码" v-model="password" @keyup.13="longs()" />
          </div>
          <p class="start">{{ start }}</p>
          <div class="find">
            <p @click="register()">免费注册</p>
            <p style="color:#921d22;margin-right:10px" @click="isshow = false" class="return">忘记密码</p>
          </div>
          <p class="teldl">
            使用
            <span @click="tztel()">手机验证码登录</span>
          </p>
          <button @click="longs()">登录</button>
        </div>

        <!--手机登录-->
        <div class="tellogin" v-if="reveal">
          <input
            type="text"
            placeholder="请输入手机号"
            v-model="tels"
            class="tels"
            @input="()=>{this.tels=this.tels.replace(/[^\d]/g,'')}"
          />
          <div class="codes">
            <input
              type="number"
              placeholder="请输入验证码"
              v-model="code"
              @input="()=>{this.code=this.code.substr(0,4)}"
            />
            <button @click="note()">{{s == 0?"发送验证码":s + 's'+ '后重新获取'}}</button>
          </div>
          <p class="starts">{{ starts }}</p>
          <div class="finds">
            <p @click="register()">免费注册</p>
            <p @click="tzpassword()">
              使用
              <span>密码登录</span>
            </p>
          </div>

          <div class="privacy">
            <div @click="yes()" :style="back">
              <img src="@/assets/images/对.png" alt v-if="backf" />
            </div>
            <p>
              同意伊莉丝钻石用户
              <span @click="$router.push('/privacypolicy')">隐私政策</span>和
              <span @click="$router.push('/orderargument')">条款细则</span>
            </p>
          </div>
          <button class="tellogin" @click="tellogin()">登录</button>
        </div>

        <!--第三方登录-->
        <div class="party">
          <div class="p_head">
            <div></div>
            <p>第三方账户登录</p>
            <div></div>
          </div>
          <div class="part">
            <img src="@/assets/images/微信1.png" alt  @click="watch()"/>
            <img src="@/assets/images/微博1.png" alt />
          </div>
        </div>
      </div>
      </div>






      <div class="login_watch" v-if="watch_show">
          <p class="title">Irisgem绑定手机号码</p>
          <div class="watch_tel">
            <input
            type="text"
            placeholder="请输入手机号"
            v-model="watch_tel"
            @input="()=>{this.watch_tel=this.watch_tel.replace(/[^\d]/g,'')}"
          />
          </div>
          <div class="watch_code">
             <input
              type="text"
              placeholder="请输入验证码"
              v-model="watch_code"
              @input="()=>{this.watch_code=this.watch_code.substr(0,4)}"
            />
            <el-button @click="getCode()" :disabled="s*1">{{s == 0?"发送验证码":s + 's'+ '后重新获取'}}</el-button>
          </div>
          <p class="starts">{{ starts }}</p>

          <div class="watch_but" @click="watch_login()">登录</div>
      </div>













      <!--找回密码-->

      <div class="log_main" v-if="isshow == false">
        <p class="title">忘记密码</p>
        <div v-if="reveal == false" class="tellogin">
          <div class="input">
            <input
              type="text"
              placeholder="请输入您注册的手机号"
              @input="()=>{this.tela=this.tela.replace(/[^\d]/g,'')}"
              v-model="tela"
            />
            <div class="codes">
              <input
                type="number"
                placeholder="请输入验证码"
                class="resacode"
                v-model="codea"
                @input="()=>{this.codea=this.codea.substr(0,4)}"
              />
              <button @click="retrieve()">{{sa == 0?"发送验证码":sa + 's'+ '后重新获取'}}</button>
            </div>
            <input type="password" placeholder="请输入新密码" v-model="newpassword" />
          </div>
          <p class="startc">{{ startc }}</p>

          <button @click="reseta()">确认重置密码</button>

          <p
            style="font-size:16px;color:#ccc;float:right;"
            class="return"
            @click="isshow = true"
          >返回登录</p>
        </div>
      </div>
    </div>
    <div class="ccc"></div>
    <!--explain-->
    <div class="explain">
      <dl v-for="item in tablist" :key="item.id">
        <dt>
          <img :src="item.img" alt />
        </dt>
        <dd>{{ item.name }}</dd>
      </dl>
    </div>
  </div>
</template>
<script>
import {
  phoneCode,
  phonelogin,
  login,
  updatepwd,
  getMy,
  myBuyCar,
  bindwx
} from "@/service/api.js";
export default {
  name: "login",
  data() {
    return {
      tablist: [
        {
          id: 1,
          name: "专属定制",
          img: require("@/assets/images/专属定制.png")
        },
        {
          id: 2,
          name: "生物科技",
          img: require("@/assets/images/生物科技.png")
        },
        {
          id: 3,
          name: "欧洲专利",
          img: require("@/assets/images/欧洲专利.png")
        },
        {
          id: 4,
          name: "国际认证",
          img: require("@/assets/images/国际认证.png")
        },
        {
          id: 5,
          name: "亲情钻石",
          img: require("@/assets/images/亲情钻石.png")
        }
      ],
      tel: "", //密码登录手机
      tels: "", //验证码登录手机
      password: "", //密码
      code: "", //验证码
      reveal: false, //tab切换
      html: "发送短信验证码", //字体
      back: "", //背景色
      backf: false, //判读是否同意
      start: "", //密码登录不正确内容
      starts: "", //验证码登录不正确内容en
      phoneStatus: 1,
      s: 0, //秒
      sa: 0,
      codea: "", //找回密码验证码
      isshow: true,
      newpassword: "", //新密码,
      startc: "", //找回密码错误提示
      tela: "", //找回密码手机号
      uid: false,
      watch_tel:'',//微信手机号码
      watch_code:'',//微信验证码
      watch_show:false,//微信登录
    };
  },
  methods: {
    //微信登录
    watch(){
      const redirecturis = encodeURIComponent(
          `https://irisgem.cn/watchlogin`
        );
        console.log(redirecturis)
         window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx60e992345e54a85f&redirect_uri=${redirecturis}&response_type=code&scope=snsapi_login&state=3d6be0a4035d839573b04816624a415e#wechat_redirect`
    },


    //微信绑定手机号
    watch_login(){
        bindwx({
          checkCode : localStorage.getItem('watch_code'),
          mobile:this.watch_tel,
          code:this.watch_code
        }).then(res=>{
            if (res.status != 200) {
             this.start = res.message;
            }
            this.uid = res.attachment.user.uid;
            this.$store.commit("user/SETUID", res.attachment.user.uid);
            this.$store.commit("user/SETTOKEN", res.attachment.token);
             getMy({
            uid: this.uid
          }).then(res => {
            this.$store.commit("user/SETUSERDATA", res.attachment);
            let loading = this.$loading({
              lock: true,
              text: "登陆成功，正在跳转...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            myBuyCar({ uid: this.uid }).then(res => {
              this.$store.commit("user/SETCAR", res.attachment.total);
            });
            if (this.$route.query.url) {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  path: this.$route.query.url
                });
              }, 300);
            } else if (this.$route.query.ids) {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  path: this.$route.query.url + this.$route.query.ids
                });
              }, 300);
            } else {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  path: "/account"
                });
              }, 300);
            }
          });
        })
    },







    //微信获取验证码
    getCode() {
      let res = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (res.test(this.watch_tel)) {
      
        this.starts = "";
        phoneCode({ mobile: this.watch_tel, type: 6 }).then(res => {
          console.log(res);

          if (res.status != 200) {
            this.starts = res.message;
          }
          this.startTime();
        });
      } else {
        this.starts = "手机号格式不正确";
      }
     },

    
    // 密码登录点击登录
    longs() {
      // console.log(this.$store.state.loggingstatus)
      let res = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (res.test(this.tel)) {
        login({ name: this.tel, pwd: this.password }).then(res => {
          // console.log(res);
          if (res.status != 200) {
            this.start = res.message;
          }
          this.uid = res.attachment.user.uid;
          this.$store.commit("user/SETUID", res.attachment.user.uid);
          this.$store.commit("user/SETTOKEN", res.attachment.token);
            getMy({
            uid: this.uid
          }).then(res => {
            this.$store.commit("user/SETUSERDATA", res.attachment);
            let loading = this.$loading({
              lock: true,
              text: "登陆成功，正在跳转...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            myBuyCar({ uid: this.uid }).then(res => {
              this.$store.commit("user/SETCAR", res.attachment.total);
            });
            if (this.$route.query.url) {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  path: this.$route.query.url
                });
              }, 300);
            } else if (this.$route.query.ids) {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  path: this.$route.query.url + this.$route.query.ids
                });
              }, 300);
            } else {
              setTimeout(() => {
                loading.close();
                this.$router.push({
                  path: "/account"
                });
              }, 300);
            }
          });
        });
      } else {
        this.start = "手机号格式不正确";
      }
    },
    // 点击切换手机登录
    tztel() {
      this.reveal = !this.reveal;
    },
    //  点击跳转密码登录
    tzpassword() {
      this.reveal = !this.reveal;
    },
    // 发送验证码
    note() {
      let res = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (res.test(this.tels)) {
        if (this.phoneStatus == 2) {
          return;
        }
        this.starts = "";
        phoneCode({ mobile: this.tels, type: 2 }).then(res => {
          console.log(res);

          if (res.status != 200) {
            this.starts = res.message;
          }
          this.phoneStatus = 2;
          this.startTime();
        });
      } else {
        this.starts = "手机号格式不正确";
      }
    },
    //修改密码发送验证码
    retrieve() {
      let res = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (res.test(this.tela)) {
        if (this.phoneStatus == 2) {
          return;
        }
        this.startc = "";
        phoneCode({ mobile: this.tela, type: 3 }).then(res => {
          console.log(res);

          if (res.status != 200) {
            this.startc = res.message;
          }
          this.phoneStatus = 2;
          this.startTimea();
        });
      } else {
        this.startc = "手机号格式不正确";
      }
    },
    // 对勾
    yes() {
      if (this.backf == false) {
        this.back = "background:#921d22";
        this.backf = true;
      } else {
        this.back = "background:#fff";
        this.backf = false;
      }
    },
    // 验证码登录
    tellogin() {
      if (this.backf == false) {
        // alert("没勾选");
      } else {
        phonelogin({ mobile: this.tels, code: this.code }).then(res => {
          // console.log(res)
          this.starts = res.message;
          this.$store.commit("user/SETUID", res.attachment.user.uid);
          this.$store.commit("user/SETTOKEN", res.attachment.token);
         
          myBuyCar({ uid: this.uid }).then(res => {
            this.$store.commit("user/SETCAR", res.attachment.total);
          });
          // console.log(this.uid)
          // console.log(res.attachment.user.uid)
             getMy({
            uid: res.attachment.user.uid
          }).then(res => {
             this.$store.commit("user/SETUSERDATA", res.attachment);
             console.log(res)
          })
          // console.log(res);
          let loading = this.$loading({
            lock: true,
            text: "登陆成功，正在跳转...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          if (this.$route.query.url) {
            setTimeout(() => {
              loading.close();
              this.$router.push({
                path: this.$route.query.url
              });
            }, 300);
          } else {
            setTimeout(() => {
              loading.close();
              this.$router.push({
                path: "/mycenter"
              });
            }, 300);
          }
          this.starts = "";
        });
      }
    },
    //注册
    register() {
      this.$router.push({
        path: "/register"
      });
    },
    //倒计时
    startTime() {
      this.s = 60;
      let time = setInterval(() => {
        if (this.s < 2) {
          clearInterval(time);
          this.phoneStatus = 3;
        }
        this.s--;
      }, 1000);
    },
    startTimea() {
      this.sa = 60;
      let time = setInterval(() => {
        if (this.sa < 2) {
          clearInterval(time);
          this.phoneStatus = 3;
        }
        this.sa--;
      }, 1000);
    },
    //重置密码
    reseta() {
      updatepwd({
        mobile: this.tela,
        code: this.codea,
        pwd: this.newpassword
      }).then(res => {
        console.log(res);
        if (res.status == 200) {
          let loading = this.$loading({
            lock: true,
            text: "修改完成，正在跳转登录...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          setTimeout(() => {
            loading.close();
            this.isshow = true;
          }, 300);
          this.tela = "";
          this.codea = "";
          this.newpassword = "";
          this.tel = "";
          this.password = "";
          this.start = "";
        }
      });
    }
  },
  mounted() {
      if(this.$route.query.show){
        this.watch_show = this.$route.query.show
      }

  }
};
</script>

<style lang="less" scoped>
.login_watch{
  width: 420px;
  height: 520px;
  background: #fff;
  box-shadow: 2px 2px 12px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  right: 10%;
  .title {
    width: 100%;
    text-align: center;
    color: #921d22;
    font-size: 22px;
    line-height: 50px;
    margin-top: 20px;
  }
   .watch_but {
    width: 82%;
    height: 52px;
    background: #921d22;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    margin: 30px 30px;
    line-height: 52px;
    text-align: center;
  }
  .watch_tel{
    width: 80%;
    margin: 40px auto;
    height: 52px;
    text-align: center;
    input{
      display: block;
      width: 92%;
      height: 50px;
      border: none;
      border-radius: 4px;
      border: 1px solid #cfcfcf;
      margin-top: 20px;
      font-size: 16px;
      padding-left: 25px;
      outline: none;
    }
  }
  .watch_code{
    width: 80%;
    margin: 0 auto;
    height: 52px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto;
    input{
      display: block;
      width: 52%;
      height: 50px;
      border: none;
      border-radius: 4px;
      border: 1px solid #cfcfcf;
      font-size: 16px;
      padding-left: 25px;
      outline: none;
    }
    button{
      height: 100%;
    }
  }
}




.login {
  width: 100%;
}
.banner {
  width: 100%;
  height: 600px;
  background: #000;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.log_main {
  width: 420px;
  height: 520px;
  background: #fff;
  box-shadow: 2px 2px 12px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  right: 10%;
  input{
    outline: none;
  }
  .title {
    width: 100%;
    text-align: center;
    color: #921d22;
    font-size: 22px;
    line-height: 50px;
    margin-top: 20px;
  }
  .input {
    width: 86%;
    margin: 0 auto;
    input {
      display: block;
      width: 80%;
      height: 50px;
      border: none;
      border-radius: 4px;
      border: 1px solid #cfcfcf;
      margin-top: 20px;
      font-size: 16px;
      padding-left: 56px;
      &:last-child {
        background: url("~@/assets/images/密码.png") no-repeat 20px center;
      }
      &:first-child {
        background: url("~@/assets/images/头像.png") no-repeat 20px center;
      }
    }
  }
  .find {
    width: 85%;
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 16px;
      &:first-child {
        color: #921d22;
      }
      &:last-child {
        color: #cccccc;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .teldl {
    text-align: center;
    line-height: 30px;
    span {
      color: #921d22;
      &:hover {
        cursor: pointer;
      }
    }
  }
  button {
    width: 82%;
    height: 52px;
    background: #921d22;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    margin: 30px 30px;
  }
  .party {
    width: 96%;
    margin: 20px auto;
    .p_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        width: 30%;
        height: 1px;
        border-top: 1px solid #cfcfcf;
      }
    }
  }
  .part {
    width: 25%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.ccc {
  background: #f5f5f5;
  width: 100%;
  height: 80px;
}
.explain {
  width: 80%;
  height: 344px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  dl {
    width: 120px;
    height: 150px;
    dt {
      width: 100%;
      height: 106px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    dd {
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-size: 20px;
      color: #666666;
    }
  }
}
.passlogin {
  height: 322px;
}

.tellogin {
  width: 86%;
  margin: 0 auto;
  height: 342px;
  .tels {
    width: 83%;
    height: 50px;
    border: none;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    margin-top: 20px;
    font-size: 16px;
    padding-left: 56px;
    background: url("~@/assets/images/头像.png") no-repeat 20px center;
    font-size: 16px;
    background-size: 17px 20px;
  }
  .codes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      width: 35%;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #cfcfcf;
      margin-top: 20px;
      font-size: 16px;
      padding-left: 56px;
      background: url("~@/assets/images/验证码.png") no-repeat 20px center;
      font-size: 16px;
      background-size: 17px 20px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
    }
    button {
      width: 40%;
      height: 50px;
      font-size: 14px;
      margin: 0;
      margin-top: 20px;
      margin-right: 5px;
      padding: 0;
      color: #000;
      background-image: linear-gradient(#fff, #ccc);
      border: 1px solid #cfcfcf;
    }
  }
  .finds {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
    font-size: 16px;
    p:last-child {
      span {
        color: #921d22;
      }
    }
    p:first-child {
      color: #921d22;
    }
    p:hover {
      cursor: pointer;
    }
  }
  .privacy {
    width: 100%;
    height: 16px;
    margin-top: 55px;
    div {
      width: 16px;
      height: 100%;
      border: 1px solid #ccc;
      float: left;
      margin-left: 15px;
      position: relative;
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    p {
      width: 90%;
      height: 20px;
      font-size: 16px;
      float: right;
      line-height: 15px;
      overflow: hidden;
      text-align: right;
      color: #999999;
      span {
        color: #000;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .tellogin {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 9px;
  }
}
.start {
  width: 84%;
  height: 15px;
  margin: 0 auto;
  color: red;
  padding-top: 10px;
  font-size: 14px;
}
.starts {
  height: 15px;
  color: red;
  padding-top: 10px;
  font-size: 14px;
}
.startc {
  height: 15px;
  color: red;
  padding-top: 10px;
  font-size: 14px;
}
.codea {
  input {
    width: 45%;
  }
}
.resacode {
  background: url("~@/assets/images/验证码.png") no-repeat 20px center !important;
}
.return:hover {
  cursor: pointer;
}
</style>
